import Hero from "../Components/Section_hero";
import TopBar from "../Components/TopBar";
import { SiWhatsapp } from "react-icons/si";

function Transcript() {
  return (
    <div>
      <TopBar />
      <Hero title="Transcript request" />

      <div className="px-4 md:px-8 lg:px-20 py-8 md:py-10 space-y-4">
        {/* Application Breakdown */}
        <div>
          <h3 className="text-gray-700 font-semibold text-xl">
            Application breakdown
          </h3>
          <p className="text-gray-700 text-sm">
            Applies to either official or unofficial/student copy
          </p>
        </div>

        {/* Additional fees */}
        <div className="text-gray-700 text-sm">
          <p>
            Transcript fee - 10,400 NGN (to be paid via student portal @
            portal.abuad.edu.ng)
          </p>
          <p>Processing - 5,000 NGN</p>
          <p>
            <b>Note:</b> if you have challenges with the Portal login/online
            payment, reach out via the whatsapp button below
          </p>
        </div>

        <div className="space-y-2">
          <div className="mb-2">
            <h3 className="text-gray-700 font-semibold text-lg">
              Additional fees (if applicable)
            </h3>
            <p className="text-gray-700 text-sm">
              Prices for both local and international shipping should be
              confirmed.
            </p>
          </div>

          {/* <div className="text-gray-700 text-sm">
            <p className="font-semibold text-sm">Shipping within Nigeria</p>
            <p>Southwest states - 5,000 NGN</p>
            <p>Other zones - 6,750 NGN</p>
          </div>

          <div className="text-gray-700 text-sm">
            <p className="font-semibold text-base">Shipping outside Nigeria</p>
            <p>Canada/UK/US - 18,500 NGN</p>
            <p>Australia - 28,250 NGN</p>
            <p>Sweden - 25,820 NGN</p>
            <p>South Africa - 27,000 NGN</p>
            <p>Asian countries - 28,500 NGN</p>
          </div> */}
        </div>

        <div className="space-y-4">
          <div>
            <h3 className="text-gray-700 font-semibold text-lg">
              After portal payment
            </h3>
            <p className="text-gray-700 text-sm">
              {" "}
              Send the following details as required via the whatsapp button
              below:
            </p>
          </div>

          <div className="text-gray-700 text-sm">
            <p>1. Type of transcript (student/official)</p>
            <p>2. Your name (as arranged in your certificate)</p>
            <p>3. Departnemt</p>
            <p>4. Matriculation No</p>
            <p>5. Year of graduation</p>
            <p>6. Shipping address (if applicable)</p>
            <p>7. Recipient's contact number (if applicable):</p>
            <p>
              8. The name of the institution/agency/organisation (for official
              copy)
            </p>
            <p>
              9. Reference number and application/reg ID should also be included
              where applicable
            </p>
            <p>
              10. Attachments, certs, forms (if applicable): ensure it is clear
              and whole
            </p>
            <p className="font-bold">
              <br />
              Note: Proof of degree completion = degree certificate, if
              requested, it should be sent to the email provided below along
              with your application details.
            </p>
            <p className="font-bold">
              <br />
              It takes about 5-20 working days depending on the numbers of
              requests on ground.
            </p>
          </div>

          <a
            href="https://wa.me/2348067565132?text=Hello%2C%20I%20just%20made%20a%20payment%20for%20a%20transcript%20request%20also%20find%20attached%20a%20prove%20of%20payment.%0A%0AType%20of%20transcript%20%28student%2Fofficial%29%3A%20%0AName%3A%0ADepartment%3A%0AMatriculation%20no%3A%0AYear%20of%20graduation%3A%0AShipping%20address%0APhone%20number%3A"
            className="mt-1 text-white inline-flex items-center space-x-1 font-semibold px-4 py-2 rounded background bg-green-400 hover:bg-green-500"
            alt="start whatsapp chat"
          >
            <span>
              <SiWhatsapp />
            </span>
            <span>Send details</span>
          </a>
        </div>

        <div>
          <h3 className="text-gray-700 font-semibold text-lg">
            For credentials evaluation services{" "}
          </h3>
          <p className="text-gray-700 text-sm">
            send certificate and other necessary documents (transcript
            labels/request forms) to: olaoyeoa@abuad.edu.ng
          </p>
        </div>
      </div>

      <footer />
    </div>
  );
}

export default Transcript;
