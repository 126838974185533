import { useDispatch } from "react-redux";
import { openModal } from "../redux/modalSlice";
import { FaUserCheck } from "react-icons/fa";

function VoterVerify() {
  const dispatch = useDispatch();
  return (
    <div className="flex justify-center items-center h-screen">
      <div>
        <span
          onClick={() => dispatch(openModal("accountCheck"))}
          className="cursor-pointer hover:bg-primary hover:text-white rounded px-4 py-3 flex items-center justify-center  duration-200 bg-primary-dark text-white"
        >
          <span className="text-lg mr-4">
            <FaUserCheck />
          </span>
          <p className="text-sm">Click to check if you are a valid voter</p>
        </span>
      </div>
    </div>
  );
}

export default VoterVerify;
