import { MdError } from "react-icons/md";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BiLoader } from "react-icons/bi";
import colleges from "../services/data/colleges";
import { MdClose } from "react-icons/md";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { postRequest } from "../services/data/requests";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../redux/modalSlice";

// generate a array of years since 2013
const years = [];
for (var i = 2013; i <= new Date().getFullYear(); i++) {
  years.push(i);
}

// component that displays message when form is submitted
function FormMessage(props) {
  const state = { ...props.state };
  const setState = props.setState;
  const dispatch = useDispatch();
  return (
    <div
      className={`form-message border border-red-500 rounded-lg bg-white p-4 ${
        state.status ? "" : "hidden"
      }`}
    >
      {/* title and close button */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1">
          <span className="text-base text-red-600">
            <AiOutlineExclamationCircle />
          </span>
          <p className="text-sm text-red-700 font-semibold">
            Something went wrong
          </p>
        </div>

        <span
          onClick={(e) => setState({ status: false })}
          className="form-message-close justify-self-end cursor-pointer"
        >
          <MdClose />
        </span>
      </div>

      {/* Error */}
      {/* {state.code ? <p className="text-xs pl-2 pt-2  text-gray-600">{state.code}</p> : ''} */}

      {/* message */}
      {state.message ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">{state.message}</p>
      ) : (
        ""
      )}

      {state.code && state.code === "V-300" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">
          You may already have an account.{" "}
          <span onClick={() => dispatch(openModal("accountCheck"))}>
            Click here
          </span>{" "}
          to check and retrieve your username and password
        </p>
      ) : (
        ""
      )}

      {state.code === "unknown" ? (
        <p className="text-xs pl-2 pt-2  text-gray-600">
          An unknown error occured.{" "}
          <a
            href={`https://wa.me/2349030009002?text=Hi%2C%20%0A%0AI%20am%20experiencing%20technical%20while%20trying%20to%20create%20an%20ABUAD%20alumni%20account`}
            alt="help"
          >
            Click here
          </a>{" "}
          to contact help{" "}
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

function SignupForm() {
  const [errorMsg, setErrorMsg] = useState({
    status: false,
    message: false,
    code: false,
  });
  const [signupState, setSignupState] = useState({
    display: "form",
    email: false,
  });

  if (signupState.display === "form") {
    return (
      <div className="py-8">
        <div className="md:py-4 md:max-w-sm lg:max-w-md md:mx-auto md:border md:shadow-lg md:rounded-lg border-gray-100">
          <div className="px-4 py-4 mx-4 rounded text-white bg-yellow-400">
            <span className="flex justify-center mx-auto text-3xl text-white mb-1">
              <MdError />
            </span>
            <p className="text-sm text-white text-center">
              You do not need signup if you completed your alumni final year
              clearance online
            </p>
          </div>

          <Formik
            initialValues={{
              firstName: "",
              middleName: "",
              surname: "",
              email: "",
              phone: "",
              matriculationNumber: "",
              college: "",
              graduationYear: "",
              homeLocation: "",
              dateOfBirth: "",
              sex: "",
              // firstName: 'Olatade',
              // middleName: 'Emmanuel',
              // surname: 'Abiona',
              // email: '4tade01@gmail.com',
              // phone: '08066197110',
              // matriculationNumber: '09/sci02/001',
              // college: 'Sciences',
              // graduationYear: '2013',
              // homeLocation: 'Lagos',
              // dateOfBirth: '1993-06-25',
              // sex: 'male'
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .trim()
                .email("Invalid email")
                .required(`Required`),
              firstName: Yup.string()
                .trim()
                .required("Required")
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              middleName: Yup.string()
                .trim()
                .required("Required")
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              surname: Yup.string()
                .trim()
                .required("Required")
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              phone: Yup.string()
                .trim()
                .matches("^[0-9]+$", "Only numbers allowed")
                .required("Required"),
              matriculationNumber: Yup.string()
                .trim()
                .required("Required")
                .matches(
                  "^[0-9]{2}/[a-zA-z]{3}[0-9]{2}/[0-9]{3,4}$",
                  "Should be like - 00/sms00/034"
                ),
              college: Yup.string()
                .trim()
                .required("Required")
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              graduationYear: Yup.string().required("Required"),
              homeLocation: Yup.string()
                .trim()
                .required("Required")
                .matches("[aA-zZs]+$", "Special characters not allowed"),
              dateOfBirth: Yup.string().required("Required"),
              sex: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              Promise.resolve(postRequest(values, "alumni")).then(function (
                response
              ) {
                // if the account creation was successful
                if (response.status === true) {
                  setSignupState({ display: "success", email: values.email });
                }
                // if acount creation failed and there is a message
                else if (response.status === false) {
                  setErrorMsg({
                    status: true,
                    message: response.message,
                    code: response?.errorCode,
                  });
                }
                // if the response did not give any status
                else {
                  setErrorMsg({ status: true, message: "", code: "unknown" });
                }
                // if status is false
                // display error code and message
                setSubmitting(false);
              });
            }}
          >
            {({ values, errors, isSubmitting, isValidating }) => (
              <Form className="px-4 space-y-4 mt-8">
                <div>
                  <h1 className="text-primary-dark font-semibold text-lg md:text-xl">
                    Signup
                  </h1>
                  <h2 className="text-gray-500 text-sm">
                    Create an ABUAD alumni account now
                  </h2>
                </div>

                {/* First name */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="firstName"
                    >
                      First name
                    </label>
                    <Field
                      className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="firstName"
                  />
                </div>

                {/* Middle name */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="middleName"
                    >
                      Middle name
                    </label>
                    <Field
                      className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="middleName"
                      type="text"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="middleName"
                  />
                </div>

                {/* Surname */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="surname"
                    >
                      Surname
                    </label>
                    <Field
                      className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="surname"
                      type="text"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="surname"
                  />
                </div>

                {/* Email */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="email"
                      type="text"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="email"
                  />
                </div>

                {/* Phone */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="phone"
                    >
                      Phone number
                    </label>
                    <Field
                      className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="phone"
                      type="text"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="phone"
                  />
                </div>

                {/* Matriculation number */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="matriculationNumber"
                    >
                      Matriculation number
                    </label>
                    <Field
                      className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="matriculationNumber"
                      type="text"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="matriculationNumber"
                  />
                </div>

                {/* College*/}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="college"
                    >
                      College
                    </label>
                    <Field
                      className="appearance-none shadow-none bg-white border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="college"
                      as="select"
                    >
                      <option value="">Select college</option>
                      {colleges.map((college) => (
                        <option value={college} key={college}>
                          {college}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="college"
                  />
                </div>

                {/* Graduation Year */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="graduationYear"
                    >
                      graduation year
                    </label>
                    <Field
                      className="appearance-none shadow-none bg-white border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="graduationYear"
                      as="select"
                    >
                      <option value="">Graduation year</option>
                      {years
                        .slice(0)
                        .reverse()
                        .map((year, i) => {
                          return (
                            <option value={year} key={i}>
                              {year}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="graduationYear"
                  />
                </div>

                {/* Home location */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="matriculationNumber"
                    >
                      Home Location (e.g Lagos, Abuja, Texas)
                    </label>
                    <Field
                      className="appearance-none shadow-none border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="homeLocation"
                      type="text"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="homeLocation"
                  />
                </div>

                {/* date of birth */}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="dateOfBirth"
                    >
                      Date of birth
                    </label>
                    <Field
                      className="appearance-none shadow-none bg-white border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="dateOfBirth"
                      type="date"
                    />
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="dateOfBirth"
                  />
                </div>

                {/* sex*/}
                <div>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <label
                      className="block text-xs font-medium text-gray-400 mb-2 md:font-semibold md:text-sm"
                      htmlFor="sex"
                    >
                      Sex
                    </label>
                    <Field
                      className="appearance-none shadow-none bg-white border border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary"
                      name="sex"
                      as="select"
                    >
                      <option value="">Select sex</option>
                      {["male", "female"].map((sex) => (
                        <option value={sex} key={sex}>
                          {sex}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <ErrorMessage
                    render={(msg) => (
                      <div className="text-red-700 text-xs mt-1">{msg}</div>
                    )}
                    name="sex"
                  />
                </div>

                <FormMessage state={errorMsg} setState={setErrorMsg} />

                {/* Button states
                  - login
                  - loading
                  - error
                */}
                <button
                  id="login-submit"
                  className="w-full mt-4 flex justify-center hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark text-white"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <span className="text-lg flex items-center space-x-1">
                      <BiLoader />
                      <p className="text-sm">Loading</p>
                    </span>
                  ) : (
                    "Create account"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  if (signupState.display === "success") {
    return (
      <div className="p-4 md:p-16">
        <div className="py-4 px-4 space-y-4 text-center mt-8md:max-w-sm lg:max-w-md mx-auto border shadow-lg rounded-lg border-gray-100">
          <h2 className="text-lg text-gray-700">We sent you an email</h2>
          <span className="flex justify-center text-5xl text-primary-dark">
            <FaEnvelopeOpenText />
          </span>
          <p className="text-sm text-gray-700">
            We sent an email to <b>{signupState.email}</b>
          </p>

          <Link
            to="/"
            id="login-submit"
            className="w-full mt-4 flex justify-center hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark text-white"
          >
            Back home
          </Link>
        </div>
      </div>
    );
  }
}

export default SignupForm;
