import Modal from "react-modal";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { BiLoader } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { FiUserX } from "react-icons/fi";
import { BiSearchAlt } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { FaMobile } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { BsPersonCheck } from "react-icons/bs";
import { findAccount, sendLoginDetails } from "../services/data/requests";
import { useState } from "react";
import { Link } from "react-router-dom";
import { hideemail, hideText } from "../services/functions";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../redux/modalSlice";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: 0,
    right: 0,
    left: "60%",
    overflow: "hidden",
    border: "none",
    padding: 0,
    height: "100vh",
  },
  overlay: {
    background: "rgba(209,213,210,0.71)",
  },
};

const mobileStyles = {
  content: {
    border: "none",
    left: "10px",
    right: "10px",
    padding: 0,
    height: "400px",
  },
  overlay: {
    background: "rgba(209,213,210,0.71)",
  },
};

function AccountCheck() {
  // account check is the reducer that handles the open and close state including the data that is displayed in the account check modal
  const { accountCheck } = useSelector((state) => state.modals);
  const [sendingLogin, setSendingLogin] = useState(false);
  const dispatch = useDispatch();

  function sendLogin(userId) {
    // put loading state to send mail button
    setSendingLogin(true);

    Promise.resolve(sendLoginDetails(userId)).then(function (response) {
      console.log(response);

      // if respnse is true display sent email message
      if (response.status === true) {
        setDisplay({
          state: "email-sent",
          details: { email: response.data["email"] },
        });
        setSendingLogin(false);
      } else {
        console.log("mail send failed");
        setSendingLogin(false);
      }
    });
  }

  // display.state can be form, success, email-sent, or fail :  all strings
  const [display, setDisplay] = useState(accountCheck);

  if (display.state === "form") {
    return (
      <div>
        <Modal
          isOpen={accountCheck.display}
          closeTimeoutMS={500}
          style={window.innerWidth > 768 ? customStyles : mobileStyles}
        >
          <div className=" min-h-full px-4 py-4 space-y-4">
            <div className="flex flex-col">
              <span
                onClick={() => dispatch(closeModal("accountCheck"))}
                className="cursor-pointer mr-2 self-end text-xl text-primary-dark"
              >
                <GrClose />
              </span>
              <h2 className=" text-center mt-2 text-base text-primary-dark font-bold">
                DO YOU HAVE AN ACCOUNT??
              </h2>
            </div>

            <Formik
              initialValues={{
                mainFilter: "matriculationNumber",
                mainInput: "",
              }}
              validationSchema={Yup.object({
                mainInput: Yup.string().trim().min(3).required("Required"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                Promise.resolve(
                  findAccount(
                    `alumni?${values.mainFilter}=${values.mainInput}`,
                    process.env.REACT_APP_ABUAD_API_ADMIN_KEY
                  )
                ).then(function (response) {
                  console.log(response);
                  // if the account creation was successful
                  if (response.status === true) {
                    const alumni = response.data[0];
                    setDisplay({
                      state: "success",
                      details: {
                        id: alumni.id,
                        email: alumni.email,
                        phone: alumni.phone,
                        matriculationNumber: alumni.matriculationNumber,
                        visible: alumni.visible,
                      },
                    });
                    // setSignupState({display : 'success', email: values.email})
                  }
                  // if acount creation failed and there is a message
                  else if (response.status === false) {
                    setDisplay({ state: "fail", details: {} });
                  }
                  // if the response did not give any status
                  else {
                    setDisplay({ state: "fail", details: {} });
                  }
                  // if status is false
                  // display error code and message
                  setSubmitting(false);
                });
              }}
            >
              {({ values, isSubmitting }) => (
                <Form>
                  <div className=" flex flex-col items-center  text-gray-600 space-y-6 ">
                    <p className="text-sm text-gray-700 text-center">
                      Check if your phone number, matriculation number or email
                      is tied to an account
                    </p>

                    <p className="text-sm text-center">
                      Your matric number should follow this format (
                      <b>00/sms00/000</b>)
                    </p>

                    <p>
                      With an account, you will be eligible to vote in the
                      upcoming elections.
                    </p>

                    {/* filters */}
                    <div className="flex space-x-2 mt-4">
                      <label
                        className={`cursor-pointer flex items-center rounded px-2 py-1 bg-gray-200 transition-all ease-out duration-300  ${
                          values.mainFilter === "matriculationNumber"
                            ? "bg-primary shadow text-white"
                            : "text-gray-500 shadow-md hover:bg-primary-light hover:text-white"
                        } `}
                      >
                        <Field
                          className="hidden"
                          type="radio"
                          name="mainFilter"
                          value="matriculationNumber"
                        />
                        <div className="flex items-center">
                          <span className="mr-1">
                            <FaGraduationCap />
                          </span>
                          <p className="text-sm">Matric number</p>
                        </div>
                      </label>

                      <label
                        className={`cursor-pointer flex items-center rounded px-2 py-1 bg-gray-200 transition-all ease-out duration-300 ${
                          values.mainFilter === "email"
                            ? "bg-primary shadow text-white"
                            : "text-gray-500 shadow-md hover:bg-primary-light hover:text-white"
                        } `}
                      >
                        <Field
                          className="hidden"
                          type="radio"
                          name="mainFilter"
                          value="email"
                        />
                        <div className="flex items-center">
                          <span className="mr-1">
                            <MdEmail />
                          </span>
                          <p className="text-sm">email</p>
                        </div>
                      </label>

                      <label
                        className={`cursor-pointer flex items-center rounded px-2 py-1 bg-gray-200 transition-all ease-out duration-300 ${
                          values.mainFilter === "phone"
                            ? "bg-primary shadow text-white"
                            : "text-gray-500 shadow-md hover:bg-primary-light hover:text-white"
                        } `}
                      >
                        <Field
                          className="hidden"
                          type="radio"
                          name="mainFilter"
                          value="phone"
                        />
                        <div className="flex items-center">
                          <span className="mr-1">
                            <FaMobile />
                          </span>
                          <p className="text-sm">phone</p>
                        </div>
                      </label>
                    </div>

                    {/* Search bar  */}
                    <div>
                      <div className="relative w-full">
                        <label className="sr-only" htmlFor="mainInput"></label>
                        <Field
                          type="text"
                          name="mainInput"
                          placeholder={`Search ${values.mainFilter}`}
                          className=" w-full border border-gray-300 bg-white h-10 px-5 pl-10 rounded-full text-base focus:outline-none"
                        />
                        <span className="absolute left-0 top-0 mt-3.5 ml-4">
                          {" "}
                          <BiSearchAlt />
                        </span>
                      </div>
                      <ErrorMessage
                        render={(msg) => (
                          <div className="text-red-700 text-xs mt-1">{msg}</div>
                        )}
                        name="mainInput"
                      />
                    </div>

                    {/* <div className="w-full "> */}
                    <button
                      id="login-submit"
                      className="w-full mt-4 flex justify-center hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 focus:bg-primary-dark bg-primary-dark text-white"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span className="text-lg flex items-center space-x-1">
                          <BiLoader />
                          <p className="text-sm">Loading</p>
                        </span>
                      ) : (
                        "Find my account"
                      )}
                    </button>
                    {/* </div> */}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    );
  }

  if (display.state === "success") {
    return (
      <div>
        <Modal
          isOpen={accountCheck.display}
          closeTimeoutMS={500}
          destroyOnClose={true}
          style={window.innerWidth > 768 ? customStyles : mobileStyles}
        >
          <div className=" min-h-full px-4 py-4 space-y-4">
            <div className="flex flex-col">
              <span
                onClick={() => dispatch(closeModal("accountCheck"))}
                className="cursor-pointer mr-2 self-end text-xl text-primary-dark"
              >
                <GrClose />
              </span>
              <span className="flex justify-center text-6xl text-green-400">
                <BsPersonCheck />
              </span>
              <h2 className=" text-center mt-2 text-base text-primary-dark font-bold">
                YES YOU HAVE AN ACCOUNT
              </h2>
              <div className="text-center border border-red-400 my-4">
                <p>
                  You are eligibile to vote in the forthcoming elections if your
                  status is "verified"
                </p>
                <p>
                  Voting commences: Friday, 17th January 2025 at 08:59 hours and
                  ending on Saturday, 18th January 2025 at 15:59 hours (GMT +1).
                </p>
              </div>
            </div>

            <div className="space-y-2">
              <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
                <span>EMAIL</span>
                <span>
                  <b>{hideemail(display.details["email"])}</b>
                </span>
              </p>
              <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
                <span>PHONE</span>
                <span>{hideText(display.details["phone"])}</span>
              </p>
              <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
                <span>MATRICULATION</span>
                <span>{display.details["matriculationNumber"]}</span>
              </p>
              <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
                <span>STATUS</span>
                <span>
                  {display.details["visible"] === "false"
                    ? "Pending verification"
                    : "Verified"}
                </span>
              </p>
            </div>

            <div>
              {display.details["visible"] === "false" ? (
                <span
                  onClick={() => setDisplay({ state: "form", details: {} })}
                  className="w-full mt-8 flex justify-center focus:bg-primary-dark hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark text-white"
                >
                  Check again
                </span>
              ) : (
                <></>
                // <span
                //   // onClick={() => sendLogin(display.details["id"])}
                //   className="w-full mt-8 flex justify-center hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark focus:bg-primary-dark text-white"
                // >
                //   {sendingLogin ? (
                //     <span className="text-lg flex items-center space-x-1">
                //       <BiLoader />
                //       <p className="text-sm">Loading</p>
                //     </span>
                //   ) : (
                //     "Send my password"
                //   )}
                // </span>
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  if (display.state === "fail") {
    return (
      <div>
        <Modal
          isOpen={accountCheck.display}
          closeTimeoutMS={500}
          style={window.innerWidth > 768 ? customStyles : mobileStyles}
        >
          <div className=" min-h-full px-4 py-4 space-y-4">
            <div className="flex flex-col">
              <span
                onClick={() => dispatch(closeModal("accountCheck"))}
                className="cursor-pointer mr-2 self-end text-xl text-primary-dark"
              >
                <GrClose />
              </span>
              <span className="flex justify-center text-6xl text-red-600">
                <FiUserX />
              </span>
              <h2 className=" text-center mt-2 text-base text-primary-dark font-bold">
                NO ACCOUNT FOUND
              </h2>
            </div>

            <div>
              <p className="text-sm text-center">
                You do not have an account with ABUAD alumni. Click the button
                below to check again or proceed to create an account
              </p>
            </div>

            <div>
              <Link
                to="/signup"
                onClick={() => dispatch(closeModal("accountCheck"))}
                className="w-full text-bold mt-8 flex justify-center focus:bg-primary-dark hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark text-white"
              >
                Create an account
              </Link>
              <span
                onClick={() => setDisplay({ state: "form", details: {} })}
                className="w-full text-sm text-bold mt-3 flex justify-center text-primary-dark"
              >
                Check again
              </span>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  if (display.state === "email-sent") {
    return (
      <div>
        <Modal
          isOpen={accountCheck.display}
          closeTimeoutMS={500}
          style={window.innerWidth > 768 ? customStyles : mobileStyles}
        >
          <div className=" min-h-full px-4 py-4 space-y-4">
            <div className="flex flex-col">
              <span
                onClick={() => dispatch(closeModal("accountCheck"))}
                className="cursor-pointer mr-2 self-end text-xl text-primary-dark"
              >
                <GrClose />
              </span>
              <span className="flex justify-center text-6xl text-primary-dark">
                <FaEnvelopeOpenText />
              </span>
              <h2 className=" text-center mt-2 text-base text-primary-dark font-bold">
                We sent you an email
              </h2>
            </div>

            <div>
              <p className="text-sm text-center">
                We have sent your username and password to the{" "}
                <b>{hideemail(display.details["email"])}</b>. Please contact
                support if you do not have access to this email address.
              </p>
            </div>

            <div>
              <span
                onClick={() => setDisplay({ state: "form", details: {} })}
                className="w-full mt-8 flex justify-center focus:bg-primary-dark hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark text-white"
              >
                Check again
              </span>
              <Link
                to="/"
                onClick={() => dispatch(closeModal("accountCheck"))}
                className="w-full mt-3 flex justify-center focus:bg-primary-dark hover:bg-primary hover:text-white text-sm rounded px-4 py-3 items-center duration-200 bg-primary-dark text-white"
              >
                Back Home
              </Link>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AccountCheck;
