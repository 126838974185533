import React, { useEffect, useState } from "react";
import axios from "axios";

const ElectionResults = () => {
  const positions = {
    President: ["C-JS1TOB", "C-8X5SJA"],
    "First Vice-President": ["C-8NIH1Q", "C-H4X1KT"],
    "Second Vice-President": ["C-L2TK6O", "C-WBG4FO"],
    "Third Vice-President": ["C-JH8CU7"],
    "Secretary General": ["C-AGZMH8"],
    "Assistant Secretary General": ["C-YOFBY6"],
    Treasurer: ["C-FJN49Z"],
    "Financial Secretary": ["C-XC721U"],
    "Social Welfare Officer": ["C-6VCEAA"],
    "Legal Adviser": ["C-FDW4IS"],
  };

  const candidates = {
    "C-JS1TOB": {
      name: "Timothy Atasie",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/president/9fe0b61abaa6c8fbf0de4f00ab75b0d9.jpeg",
    },

    "C-8X5SJA": {
      name: "Bolanle Wellington Alufa",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/president/14f64786092e3267b856be90002d77b4.jpeg",
    },

    "C-8NIH1Q": {
      name: "Oluwafunmilola Waleola",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/firstvice-president/a7cf49669effac472e82d972e1efb48c.jpeg",
    },

    "C-H4X1KT": {
      name: "Daniel Musa",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/firstvice-president/28e494ae8ce22fde2d84a539150b236c.jpeg",
    },

    "C-L2TK6O": {
      name: "Nsikak Daniel",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/secondvice-president/d56403ddc4bc5aa82b06f53a07cd9cad.jpeg",
    },

    "C-WBG4FO": {
      name: "Fagbemi Elijah",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/secondvice-president/fa65801cb5389fb164f9bb8b2b7e2289.jpeg",
    },

    "C-JH8CU7": {
      name: "Blessing Ihaza",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/thirdvice-president/af2506290699bf3c0f84a3dfad161631.jpeg",
    },

    "C-AGZMH8": {
      name: "Femi Ishola",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/secretarygeneral/45c2c828f170baf1c846870e349efcc6.jpeg",
    },

    "C-YOFBY6": {
      name: "Blessing Mukorho",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/assistantsecretarygeneral/d006f3e183be0719bc92bad79556a5c9.jpeg",
    },

    "C-FJN49Z": {
      name: "Tolulope Adams",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/treasurer/c647ae22033d1d4757e741803ad45653.jpeg",
    },

    "C-XC721U": {
      name: "Mark Achukwu",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/financialsecretary/3384d20efff3e7d4c6cdc29aad229d83.jpeg",
    },

    "C-6VCEAA": {
      name: "Lukman Oshioke",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/socialwelfareofficer/821bfe30a2cb99613e70e49f7e342487.jpeg",
    },

    "C-FDW4IS": {
      name: "Toluwani Alabi",
      image:
        "https://hego3rip.com/api/abuad-alumni/avatar/election/2024/legaladviser/c5a7e8cb517ee2c6b0ba309c921b81bd.jpeg",
    },
  };

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(30 * 60); // 30 minutes in seconds

  const fetchData = async () => {
    try {
      const positionVotesPromises = Object.keys(positions).map(
        async (position) => {
          const { data: positionData } = await axios.get(
            `https://hego3rip.com/api/abuad-alumni/election-vote?office=${position}`,
            {
              headers: {
                Authorization: "sk_live_eea599fe-3da9-4748-ee0e-729c31438887",
              },
            }
          );
          const totalVotesForPosition = positionData.status
            ? parseInt(positionData.meta.total, 10)
            : 0;

          const candidateVotesPromises = positions[position].map(
            async (candidateId) => {
              const { data: candidateData } = await axios.get(
                `https://hego3rip.com/api/abuad-alumni/election-vote?candidateId=${candidateId}`,
                {
                  headers: {
                    Authorization:
                      "sk_live_eea599fe-3da9-4748-ee0e-729c31438887",
                  },
                }
              );
              const candidateVotes = candidateData.status
                ? parseInt(candidateData.meta.total, 10)
                : 0;

              return { candidateId, votes: candidateVotes };
            }
          );

          const candidates = await Promise.all(candidateVotesPromises);

          return {
            position,
            totalVotes: totalVotesForPosition,
            candidates: candidates.map((candidate) => ({
              ...candidate,
              percentage: (
                (candidate.votes / totalVotesForPosition) *
                100
              ).toFixed(2),
            })),
          };
        }
      );

      const results = await Promise.all(positionVotesPromises);

      const formattedData = results.reduce((acc, result) => {
        acc[result.position] = result;
        return acc;
      }, {});

      setData(formattedData);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch data. Please try again later.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Countdown timer logic
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          window.location.reload(); // Refresh the page
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Convert seconds to MM:SS format
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  if (loading) {
    return (
      <div className="text-center mt-10 text-xl">
        Loading election results...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-10 text-xl text-red-500">{error}</div>
    );
  }

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-center mb-6">Election Results</h1>
      {/* Countdown Timer */}
      <div className="text-center bg-primary-light text-primary-dark p-2 rounded mb-6">
        Refreshing in: <span className="font-bold">{formatTime(timer)}</span>
      </div>
      {Object.keys(data).map((position) => (
        <div key={position} className="mb-6 border-b pb-4">
          <h2 className="text-xl font-semibold mb-2">{position}</h2>
          <p className="text-gray-600 mb-4">
            Total Votes:{" "}
            <span className="font-bold">{data[position].totalVotes}</span>
          </p>
          <div className="space-y-2">
            {data[position].candidates.map((candidate) => (
              <div
                key={candidate.candidateId}
                className="flex relative justify-between items-center bg-gray-100 pr-3 pb-3 rounded-lg shadow"
              >
                <div className="absolute w-full border border-primary-light  bottom-0 -left-0 h-3">
                  <div
                    className="h-3"
                    style={{
                      backgroundColor: "#CEC9FC",
                      width: `${candidate.percentage}%`,
                    }}
                  ></div>
                </div>
                <div className="flex items-center gap-x-4">
                  <div>
                    <img
                      className="block mx-auto w-24"
                      src={candidates[candidate.candidateId].image}
                      alt="candidate"
                    />
                  </div>
                  <span className=" font-bold text-lg">
                    {candidates[candidate.candidateId].name}
                  </span>
                </div>

                <span>
                  Votes: <span className="font-bold">{candidate.votes}</span>
                </span>
                <span>
                  Percentage:{" "}
                  <span className="font-bold">{candidate.percentage}%</span>
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ElectionResults;
